
import React, { useState, useEffect } from "react"
import axios from "axios"
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

const Hero = ({user: { userDetails }, admin: { accesslevels }}) => {

    const [herodata, setherodata] = useState(null)
    const [updatedhero, setupdatedhero] = useState(null)
    const [editFlag, setEditFlag] = useState(false)
    let history = useHistory()

    const defitem = {
        title: "",
        subtitle: "",
        description: "",
        img: "",
        btn: "",
        url: ""
    }

    const access = accesslevels.find(item => item.portal_editor !== "NA")

    useEffect(() => {
        if(access === undefined || access.length <=0 ){
            history.push('/unauthorized')
            return;
        }
        const config = {}
        axios('https://kaizoku.kurogaming.com/api/products/kurodata?type=hero', config).then(res => {
            setherodata(res.data)
            setupdatedhero(res.data)
        })
    }, [])

    const addItem = (index) => {
        let temphero = [...updatedhero]
        temphero.splice(index+1, 0, defitem)
        setupdatedhero(temphero)
    }

    const removeItem = (index) => {
        let temphero = [...updatedhero]
        temphero = temphero.filter((_, i) => i !== index)
        setupdatedhero(temphero)
    }

    const editHandler = () => {
        setEditFlag(true)
    }

    const cancelHandler = () => {
        setupdatedhero(herodata)
        setEditFlag(false)
    }

    const updateHandler = (e, index, key) => {
        let temphero = [...updatedhero]
        temphero[index][key] = e.target.value
        setupdatedhero(temphero)
    }

    const submitHandler = () => {
        const config = {}
        axios.post(process.env.REACT_APP_KG_API_URL + 'kuroadmin/kurodata?content_type=hero', updatedhero, config)
        .then((res) => {
            setherodata(res.data)
            setEditFlag(false)
        })
    }

    return (
        <>
        {herodata !== null && 
            <div className="hero-wrapper">
            {editFlag ? (
                <div className="hero-container">
                    <div className="title_wrap col_mob">
                        <p className="txt-light">Hero Carousel</p>
                        <span className="prod-span" onClick={() => addItem(-1)}>
                        <svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"></path>
                        </svg>
                        </span>
                    </div>
                    <div>
                    {updatedhero.map((item, i) =>
                    <table key={i} className="hero_table border input_mob txt-light">
                        <tbody>
                            <tr>
                                <td>Title</td>
                                <td><textarea value={item.title} onChange={(e) => updateHandler(e, i, "title")}/></td>
                                <td rowSpan={6}>
                                <span className="prod-span" onClick={() => addItem(i)}>
                                    <svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"></path>
                                    </svg>
                                </span>
                                <span className="prod-span" onClick={() => removeItem(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/>
                                </svg>
                                </span>
                                </td>
                            </tr>
                            <tr>
                                <td>Subtitle</td>
                                <td><textarea  onChange={(e) => updateHandler(e, i, "subtitle")} value={item.subtitle} /></td>
                            </tr>
                            <tr>
                                <td>Description</td>
                                <td><textarea maxLength={20000} value={item.description} onChange={(e) => updateHandler(e, i, "description")} /></td>
                            </tr>
                            <tr>
                                <td>Images</td>
                                <td><textarea  value={item.img} onChange={(e) => updateHandler(e, i, "img")} /></td>
                            </tr>
                            <tr>
                                <td>Link Text</td>
                                <td><textarea  value={item.btn}  onChange={(e) => updateHandler(e, i, "btn")}  /></td>
                            </tr>
                            <tr>
                                <td>Link Url</td>
                                <td><textarea  value={item.url} onChange={(e) => updateHandler(e, i, "url")} /></td>
                            </tr>
                        </tbody>
                    </table>
                    )}
                    </div>
                    <span>
                        <ul className="btns grid_item">
                            <li>
                                <button onClick={submitHandler}>Submit</button>
                            </li>
                            <li>
                                <button onClick={() => cancelHandler()}>Cancel</button>
                            </li>
                        </ul>
                    </span>
                </div>
            ) : (
                <div className="hero-container">
                    <div className="col_mob">
                        <h3 className="txt-light">Hero Carousel</h3>
                    </div>
                    <div>
                        {herodata.map((item, i) => (
                        <table key={i} className="hero_table border input_mob txt-light">
                            <tbody>
                                <tr>
                                    <td>Title</td>
                                    <td>{item.title}</td>
                                </tr>
                                <tr>
                                    <td>Subtitle</td>
                                    <td>{item.subtitle}</td>
                                </tr>
                                <tr>
                                    <td>Description</td>
                                    <td>{item.description}</td>
                                </tr>
                                <tr>
                                    <td>Images</td>
                                    <td>{item.img}</td>
                                </tr>
                                <tr>
                                    <td>Link Text</td>
                                    <td>{item.btn}</td>
                                </tr>
                                <tr>
                                    <td>Link Url</td>
                                    <td>{item.url}</td>
                                </tr>
                            </tbody>
                        </table>
                        ))}
                        <ul className="btns">
                        {accesslevels.find(item => item.portal_editor==="edit" || userDetails.access==="Super")?<li> <button className="btns" onClick={() => editHandler()}>Edit</button></li>:null}
                        </ul>

                    </div>
                </div>
            )}
            </div>
        }
        </>
    )
}

const mapStateToProps = state => ({
    user: state.user,
    admin: state.admin
})

export default connect(mapStateToProps)(Hero)