import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import KuroLink from "../../components/common/KuroLink"

const Employees = ({ user: { token }, admin: { employees, accesslevels } }) => {
    const [emps, setemps] = useState(null)

    useEffect(() => {
        setemps(employees)
    }, [employees])

    const updateStatus = (e, index) => {
        let tempemps = [...emps]
        tempemps[index]["user_status"] = e.target.value
        setemps(tempemps)
    }

    const updateHandler = (userid) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        const user_status = emps.filter(emp => emp.userid === userid)[0]["user_status"]

        const body = { 'user_status': user_status }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/empupdate?userid=' + userid, body, config)
        .then((res) => {
            setemps(res.data)
        })
    }

    return (
        <div className='employees txt-light-grey'>
            <h2>List of Employees</h2>
            {accesslevels?.find(item => item.employees === "edit" || item.employees === "write") && (
            <KuroLink to="/create-emp" classList="btn pri0 solid">
                Add New Employee
            </KuroLink>
            )}
            <p>&nbsp;</p>
            <table className='border even_odd table_mob'>
                <tbody>
                    <tr>
                        <th>Emp ID</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Access</th>
                        <th>Last Logged In</th>
                        <th>Status</th>
                        <th>Approval</th>
                    </tr>
                    {emps !== null && emps.map((emp, i) =>
                        <tr key={i}>
                            <td>{accesslevels.find(item => item.profile === "edit") ? <KuroLink to={'/user/profile/' + emp.userid}>{emp.userid}</KuroLink> : emp.userid}</td>
                            <td>{emp.name}</td>
                            <td>{emp.phone}</td>
                            <td>{emp.email}</td>
                            <td>{emp.role}</td>
                            <td>{emp.access}</td>
                            <td>{emp.last_login}</td>
                            <td>
                                {accesslevels.find(item => item.employees === "edit" || item.employees === 'write') ? (
                                    <select onChange={(e) => updateStatus(e, i)} value={emp.user_status  !== null ? emp.user_status : "Awaiting Approval"} >
                                        <option value="">Select the Status</option>
                                        <option value="Awaiting Approval">Awaiting Approval</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                ) : (
                                    emp.user_status
                                )}
                            </td>
                            {accesslevels.find(item => item.employees === "edit" || item.employees === 'write') ?(
                                <td>
                                    <ul className='btns'>
                                        <li>
                                            <button onClick={() => updateHandler(emp.userid)}>Update</button>
                                        </li>
                                    </ul>
                                </td>):(<td>You Don`t Have Access</td>)
                            }
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    admin: state.admin
})

export default connect(mapStateToProps)(Employees)