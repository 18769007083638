import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'

const PreBuilts = ({ products: { presetData } }) => {
    const [prebuilds, setPrebuilds] = useState([])
    const [editbuild, seteditbuild] = useState(null)
    const [editid, seteditid] = useState("")
    const [dropdownid, setdropdownid] = useState("")
    const [tabIndex, setTabIndex] = useState(0)

    useEffect(() => {
        axios.get(process.env.REACT_APP_KG_API_URL + 'kuroadmin/gettingprebuilts').then(res => {
            setPrebuilds(res.data)
        })
    }, [])

    const compOptions = (type) => {
        const tempArray = []
        presetData !== null && presetData.filter(ps => ps.type === type)[0]["list"].map(item => {
            return (
                tempArray.push({ value: item.presetid, label: item.kuro_title })
            )
        })
        return tempArray
    }

    const updatebuild = (e, key) => {
        let tempbuild = {...editbuild}
        tempbuild["presets"][key] = Array.isArray(e) ? e.map(ps => ps.value) : []
        seteditbuild(tempbuild)
    }

    const buildeditHandler = (pid) => {
        seteditbuild(prebuilds.find(pb => pb.productid === pid))
        seteditid(pid)
        setdropdownid(pid)
    }

    const cancelHandler = () => {
        seteditid("")
        seteditbuild(null)
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        axios.post(process.env.REACT_APP_KG_API_URL + 'kuroadmin/prebuilds?type=true', editbuild, config)
        .then((res) => {
            setPrebuilds(res.data)
            seteditid("")
        })
    }

    return (
        <div>
            <div className="tabs">
                <button onClick={() => setTabIndex(0)} className={"tab txt-grey" + (tabIndex === 0 ? " active" : "")}>Active</button>
                <button onClick={() => setTabIndex(1)} className={"tab txt-grey" + (tabIndex === 1 ? " active" : "")}>Inactive</button>
                <button onClick={() => setTabIndex(2)} className={"tab txt-grey" + (tabIndex === 2 ? " active" : "")}>All</button>
            </div>
            <div className="tab-panels">
                <div className={"tab-panel" + (tabIndex === 0 ? " active" : "")}>
                    <table className="border even_odd">
                        <thead>
                            <th>Product Id</th>
                            <th>Build Title</th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                        {prebuilds.length > 0 && presetData !== null && prebuilds.filter(ps => ps.active === true).map((prebuild, index) =>
                            <tr key={index}>
                                <td>{prebuild.productid}</td>
                                <td style={{ position: 'relative' }}>
                                    <table className='no-border'>
                                        <thead>
                                            <td>
                                            {prebuild.title}
                                            {dropdownid === prebuild.productid ? (
                                                <button className="arrow" onClick={() => setdropdownid("")}>▲</button>
                                            ) : (
                                                <button className="arrow" onClick={() => setdropdownid(prebuild.productid)}>▼</button>
                                            )}
                                            </td>
                                        </thead>
                                        {dropdownid === prebuild.productid &&
                                        <tbody>
                                            <td style={{ border: "none" }}>
                                            {editid === prebuild.productid ? (
                                                editbuild !== null &&
                                                <table style={{ margin: 0 }} className="border even_odd" cellPadding="0" cellSpacing="0">
                                                    <tbody>
                                                        <tr>
                                                            <td>Processor</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("cpu")} isMulti onChange={(e) => updatebuild(e, "cpu")} defaultValue={(editbuild.presets.cpu && presetData !== null) && editbuild.presets.cpu.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "cpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Power Supply Unit</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("psu")} isMulti onChange={(e) => updatebuild(e, "psu")} defaultValue={(editbuild.presets.psu && presetData !== null) && editbuild.presets.psu.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "psu")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Case</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("tower")} isMulti onChange={(e) => updatebuild(e, "tower")} defaultValue={(editbuild.presets.tower && presetData !== null) && editbuild.presets.tower.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "tower")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Motherboard</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("mob")} isMulti onChange={(e) => updatebuild(e, "mob")} defaultValue={(editbuild.presets.mob && presetData !== null) && editbuild.presets.mob.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "mob")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>SSD</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("ssd")} isMulti onChange={(e) => updatebuild(e, "ssd")} defaultValue={(editbuild.presets.ssd && presetData !== null) && editbuild.presets.ssd.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "ssd")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Fans</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("fans")} isMulti onChange={(e) => updatebuild(e, "fans")} defaultValue={(editbuild.presets.fans && presetData !== null) && editbuild.presets.fans.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "fans")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Graphics Card</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("gpu")} isMulti onChange={(e) => updatebuild(e, "gpu")} defaultValue={(editbuild.presets.gpu && presetData !== null) && editbuild.presets.gpu.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "gpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>HDD</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("hdd")} isMulti onChange={(e) => updatebuild(e, "hdd")} defaultValue={(editbuild.presets.hdd && presetData !== null) && editbuild.presets.hdd.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "hdd")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>WiFi</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("wifi")} isMulti onChange={(e) => updatebuild(e, "wifi")} defaultValue={(editbuild.presets.wifi && presetData !== null) && editbuild.presets.wifi.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "wifi")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>RAM</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("ram")} isMulti onChange={(e) => updatebuild(e, "ram")} defaultValue={(editbuild.presets.ram && presetData !== null) && editbuild.presets.ram.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "ram")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Cooler</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("cooler")} isMulti onChange={(e) => updatebuild(e, "cooler")} defaultValue={(editbuild.presets.cooler && presetData !== null) && editbuild.presets.cooler.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "cooler")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Operating System</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("os")} isMulti onChange={(e) => updatebuild(e, "os")} defaultValue={(editbuild.presets.os && presetData !== null) && editbuild.presets.os.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "os")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Build Fees</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("build_fees")} isMulti onChange={(e) => updatebuild(e, "build_fees")} defaultValue={(editbuild.presets.build_fees && presetData !== null) && editbuild.presets.build_fees.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "build_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Shipping Fees</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("shp_fees")} isMulti onChange={(e) => updatebuild(e, "shp_fees")} defaultValue={(editbuild.presets.shp_fees && presetData !== null) && editbuild.presets.shp_fees.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "shp_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Warranty</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("warranty")} isMulti onChange={(e) => updatebuild(e, "warranty")} defaultValue={(editbuild.presets.warranty && presetData !== null) && editbuild.presets.warranty.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "warranty")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                ) : (
                                                <table style={{ margin: 0 }} className="border even_odd" cellPadding="0" cellSpacing="0">
                                                    <tbody>
                                                        <tr>
                                                            <td>Processor</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.cpu.length > 0 && prebuild.presets.cpu.map(ps => <>{presetData.filter(psgrp => psgrp.type === "cpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Power Supply Unit</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.psu.length > 0 && prebuild.presets.psu.map(ps => <>{presetData.filter(psgrp => psgrp.type === "psu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Case</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.tower.length > 0 && prebuild.presets.tower.map(ps => <>{presetData.filter(psgrp => psgrp.type === "tower")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Motherboard</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.mob.length > 0 && prebuild.presets.mob.map(ps => <>{presetData.filter(psgrp => psgrp.type === "mob")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>SSD</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.ssd.length > 0 && prebuild.presets.ssd.map(ps => <>{presetData.filter(psgrp => psgrp.type === "ssd")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Fans</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.fans.length > 0 && prebuild.presets.fans.map(ps => <>{presetData.filter(psgrp => psgrp.type === "fans")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Graphics Card</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.gpu.length > 0 && prebuild.presets.gpu.map(ps => <>{presetData.filter(psgrp => psgrp.type === "gpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>HDD</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.hdd.length > 0 && prebuild.presets.hdd.map(ps => <>{presetData.filter(psgrp => psgrp.type === "hdd")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>WiFi</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.wifi.length > 0 && prebuild.presets.wifi.map(ps => <>{presetData.filter(psgrp => psgrp.type === "wifi")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>RAM</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.ram.length > 0 && prebuild.presets.ram.map(ps => <>{presetData.filter(psgrp => psgrp.type === "ram")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Cooler</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.cooler.length > 0 && prebuild.presets.cooler.map(ps => <>{presetData.filter(psgrp => psgrp.type === "cooler")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Operating System</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.os.length > 0 && prebuild.presets.os.map(ps => <>{presetData.filter(psgrp => psgrp.type === "os")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Build Fees</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.build_fees.length > 0 && prebuild.presets.build_fees.map(ps => <>{presetData.filter(psgrp => psgrp.type === "build_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Shipping Fees</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.shp_fees.length > 0 && prebuild.presets.shp_fees.map(ps => <>{presetData.filter(psgrp => psgrp.type === "shp_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Warranty</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.warranty.length > 0 && prebuild.presets.warranty.map(ps => <>{presetData.filter(psgrp => psgrp.type === "warranty")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                )}
                                            </td>
                                        </tbody>
                                        }
                                    </table>
                                </td>
                                <td>
                                {editid === prebuild.productid ? (
                                    <>
                                    <button onClick={submitHandler}>Save</button>
                                    <button style={{ marginTop: "10px" }} onClick={cancelHandler}>Cancel</button>
                                    </>
                                ) : (
                                    <button onClick={() => {buildeditHandler(prebuild.productid)}}>Edit</button>
                                )}
                                </td>
                            </tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
                <div className={"tab-panel" + (tabIndex === 1 ? " active" : "")}>
                    <table className="border even_odd">
                        <thead>
                            <th>Product Id</th>
                            <th>Build Title</th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                        {prebuilds.length > 0 && presetData !== null && prebuilds.filter(ps => ps.active === true).map((prebuild, index) =>
                            <tr key={index}>
                                <td>{prebuild.productid}</td>
                                <td style={{ position: 'relative' }}>
                                    <table className='no-border'>
                                        <thead>
                                            <td>
                                            {prebuild.title}
                                            {dropdownid === prebuild.productid ? (
                                                <button className="arrow" onClick={() => setdropdownid("")}>▲</button>
                                            ) : (
                                                <button className="arrow" onClick={() => setdropdownid(prebuild.productid)}>▼</button>
                                            )}
                                            </td>
                                        </thead>
                                        {dropdownid === prebuild.productid &&
                                        <tbody>
                                            <td style={{ border: "none" }}>
                                            {editid === prebuild.productid ? (
                                                editbuild !== null &&
                                                <table style={{ margin: 0 }} className="border even_odd" cellPadding="0" cellSpacing="0">
                                                    <tbody>
                                                        <tr>
                                                            <td>Processor</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("cpu")} isMulti onChange={(e) => updatebuild(e, "cpu")} defaultValue={(editbuild.presets.cpu && presetData !== null) && editbuild.presets.cpu.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "cpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Power Supply Unit</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("psu")} isMulti onChange={(e) => updatebuild(e, "psu")} defaultValue={(editbuild.presets.psu && presetData !== null) && editbuild.presets.psu.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "psu")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Case</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("tower")} isMulti onChange={(e) => updatebuild(e, "tower")} defaultValue={(editbuild.presets.tower && presetData !== null) && editbuild.presets.tower.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "tower")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Motherboard</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("mob")} isMulti onChange={(e) => updatebuild(e, "mob")} defaultValue={(editbuild.presets.mob && presetData !== null) && editbuild.presets.mob.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "mob")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>SSD</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("ssd")} isMulti onChange={(e) => updatebuild(e, "ssd")} defaultValue={(editbuild.presets.ssd && presetData !== null) && editbuild.presets.ssd.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "ssd")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Fans</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("fans")} isMulti onChange={(e) => updatebuild(e, "fans")} defaultValue={(editbuild.presets.fans && presetData !== null) && editbuild.presets.fans.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "fans")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Graphics Card</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("gpu")} isMulti onChange={(e) => updatebuild(e, "gpu")} defaultValue={(editbuild.presets.gpu && presetData !== null) && editbuild.presets.gpu.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "gpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>HDD</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("hdd")} isMulti onChange={(e) => updatebuild(e, "hdd")} defaultValue={(editbuild.presets.hdd && presetData !== null) && editbuild.presets.hdd.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "hdd")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>WiFi</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("wifi")} isMulti onChange={(e) => updatebuild(e, "wifi")} defaultValue={(editbuild.presets.wifi && presetData !== null) && editbuild.presets.wifi.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "wifi")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>RAM</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("ram")} isMulti onChange={(e) => updatebuild(e, "ram")} defaultValue={(editbuild.presets.ram && presetData !== null) && editbuild.presets.ram.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "ram")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Cooler</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("cooler")} isMulti onChange={(e) => updatebuild(e, "cooler")} defaultValue={(editbuild.presets.cooler && presetData !== null) && editbuild.presets.cooler.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "cooler")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Operating System</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("os")} isMulti onChange={(e) => updatebuild(e, "os")} defaultValue={(editbuild.presets.os && presetData !== null) && editbuild.presets.os.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "os")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Build Fees</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("build_fees")} isMulti onChange={(e) => updatebuild(e, "build_fees")} defaultValue={(editbuild.presets.build_fees && presetData !== null) && editbuild.presets.build_fees.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "build_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Shipping Fees</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("shp_fees")} isMulti onChange={(e) => updatebuild(e, "shp_fees")} defaultValue={(editbuild.presets.shp_fees && presetData !== null) && editbuild.presets.shp_fees.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "shp_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Warranty</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("warranty")} isMulti onChange={(e) => updatebuild(e, "warranty")} defaultValue={(editbuild.presets.warranty && presetData !== null) && editbuild.presets.warranty.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "warranty")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                ) : (
                                                <table style={{ margin: 0 }} className="border even_odd" cellPadding="0" cellSpacing="0">
                                                    <tbody>
                                                        <tr>
                                                            <td>Processor</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.cpu.length > 0 && prebuild.presets.cpu.map(ps => <>{presetData.filter(psgrp => psgrp.type === "cpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Power Supply Unit</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.psu.length > 0 && prebuild.presets.psu.map(ps => <>{presetData.filter(psgrp => psgrp.type === "psu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Case</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.tower.length > 0 && prebuild.presets.tower.map(ps => <>{presetData.filter(psgrp => psgrp.type === "tower")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Motherboard</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.mob.length > 0 && prebuild.presets.mob.map(ps => <>{presetData.filter(psgrp => psgrp.type === "mob")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>SSD</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.ssd.length > 0 && prebuild.presets.ssd.map(ps => <>{presetData.filter(psgrp => psgrp.type === "ssd")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Fans</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.fans.length > 0 && prebuild.presets.fans.map(ps => <>{presetData.filter(psgrp => psgrp.type === "fans")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Graphics Card</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.gpu.length > 0 && prebuild.presets.gpu.map(ps => <>{presetData.filter(psgrp => psgrp.type === "gpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>HDD</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.hdd.length > 0 && prebuild.presets.hdd.map(ps => <>{presetData.filter(psgrp => psgrp.type === "hdd")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>WiFi</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.wifi.length > 0 && prebuild.presets.wifi.map(ps => <>{presetData.filter(psgrp => psgrp.type === "wifi")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>RAM</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.ram.length > 0 && prebuild.presets.ram.map(ps => <>{presetData.filter(psgrp => psgrp.type === "ram")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Cooler</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.cooler.length > 0 && prebuild.presets.cooler.map(ps => <>{presetData.filter(psgrp => psgrp.type === "cooler")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Operating System</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.os.length > 0 && prebuild.presets.os.map(ps => <>{presetData.filter(psgrp => psgrp.type === "os")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Build Fees</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.build_fees.length > 0 && prebuild.presets.build_fees.map(ps => <>{presetData.filter(psgrp => psgrp.type === "build_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Shipping Fees</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.shp_fees.length > 0 && prebuild.presets.shp_fees.map(ps => <>{presetData.filter(psgrp => psgrp.type === "shp_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Warranty</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.warranty.length > 0 && prebuild.presets.warranty.map(ps => <>{presetData.filter(psgrp => psgrp.type === "warranty")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                )}
                                            </td>
                                        </tbody>
                                        }
                                    </table>
                                </td>
                                <td>
                                {editid === prebuild.productid ? (
                                    <>
                                    <button onClick={submitHandler}>Save</button>
                                    <button style={{ marginTop: "10px" }} onClick={cancelHandler}>Cancel</button>
                                    </>
                                ) : (
                                    <button onClick={() => {buildeditHandler(prebuild.productid)}}>Edit</button>
                                )}
                                </td>
                            </tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
                <div className={"tab-panel" + (tabIndex === 2 ? " active" : "")}>
                    <table className="border even_odd">
                        <thead>
                            <th>Product Id</th>
                            <th>Build Title</th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                        {prebuilds.length > 0 && presetData !== null && prebuilds.filter(ps => ps.active === true).map((prebuild, index) =>
                            <tr key={index}>
                                <td>{prebuild.productid}</td>
                                <td style={{ position: 'relative' }}>
                                    <table className='no-border'>
                                        <thead>
                                            <td>
                                            {prebuild.title}
                                            {dropdownid === prebuild.productid ? (
                                                <button className="arrow" onClick={() => setdropdownid("")}>▲</button>
                                            ) : (
                                                <button className="arrow" onClick={() => setdropdownid(prebuild.productid)}>▼</button>
                                            )}
                                            </td>
                                        </thead>
                                        {dropdownid === prebuild.productid &&
                                        <tbody>
                                            <td style={{ border: "none" }}>
                                            {editid === prebuild.productid ? (
                                                editbuild !== null &&
                                                <table style={{ margin: 0 }} className="border even_odd" cellPadding="0" cellSpacing="0">
                                                    <tbody>
                                                        <tr>
                                                            <td>Processor</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("cpu")} isMulti onChange={(e) => updatebuild(e, "cpu")} defaultValue={(editbuild.presets.cpu && presetData !== null) && editbuild.presets.cpu.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "cpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Power Supply Unit</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("psu")} isMulti onChange={(e) => updatebuild(e, "psu")} defaultValue={(editbuild.presets.psu && presetData !== null) && editbuild.presets.psu.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "psu")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Case</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("tower")} isMulti onChange={(e) => updatebuild(e, "tower")} defaultValue={(editbuild.presets.tower && presetData !== null) && editbuild.presets.tower.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "tower")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Motherboard</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("mob")} isMulti onChange={(e) => updatebuild(e, "mob")} defaultValue={(editbuild.presets.mob && presetData !== null) && editbuild.presets.mob.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "mob")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>SSD</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("ssd")} isMulti onChange={(e) => updatebuild(e, "ssd")} defaultValue={(editbuild.presets.ssd && presetData !== null) && editbuild.presets.ssd.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "ssd")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Fans</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("fans")} isMulti onChange={(e) => updatebuild(e, "fans")} defaultValue={(editbuild.presets.fans && presetData !== null) && editbuild.presets.fans.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "fans")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Graphics Card</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("gpu")} isMulti onChange={(e) => updatebuild(e, "gpu")} defaultValue={(editbuild.presets.gpu && presetData !== null) && editbuild.presets.gpu.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "gpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>HDD</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("hdd")} isMulti onChange={(e) => updatebuild(e, "hdd")} defaultValue={(editbuild.presets.hdd && presetData !== null) && editbuild.presets.hdd.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "hdd")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>WiFi</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("wifi")} isMulti onChange={(e) => updatebuild(e, "wifi")} defaultValue={(editbuild.presets.wifi && presetData !== null) && editbuild.presets.wifi.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "wifi")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>RAM</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("ram")} isMulti onChange={(e) => updatebuild(e, "ram")} defaultValue={(editbuild.presets.ram && presetData !== null) && editbuild.presets.ram.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "ram")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Cooler</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("cooler")} isMulti onChange={(e) => updatebuild(e, "cooler")} defaultValue={(editbuild.presets.cooler && presetData !== null) && editbuild.presets.cooler.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "cooler")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Operating System</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("os")} isMulti onChange={(e) => updatebuild(e, "os")} defaultValue={(editbuild.presets.os && presetData !== null) && editbuild.presets.os.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "os")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Build Fees</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("build_fees")} isMulti onChange={(e) => updatebuild(e, "build_fees")} defaultValue={(editbuild.presets.build_fees && presetData !== null) && editbuild.presets.build_fees.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "build_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Shipping Fees</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("shp_fees")} isMulti onChange={(e) => updatebuild(e, "shp_fees")} defaultValue={(editbuild.presets.shp_fees && presetData !== null) && editbuild.presets.shp_fees.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "shp_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                            <td>Warranty</td>
                                                            <td colSpan="2">
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions("warranty")} isMulti onChange={(e) => updatebuild(e, "warranty")} defaultValue={(editbuild.presets.warranty && presetData !== null) && editbuild.presets.warranty.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "warranty")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                ) : (
                                                <table style={{ margin: 0 }} className="border even_odd" cellPadding="0" cellSpacing="0">
                                                    <tbody>
                                                        <tr>
                                                            <td>Processor</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.cpu.length > 0 && prebuild.presets.cpu.map(ps => <>{presetData.filter(psgrp => psgrp.type === "cpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Power Supply Unit</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.psu.length > 0 && prebuild.presets.psu.map(ps => <>{presetData.filter(psgrp => psgrp.type === "psu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Case</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.tower.length > 0 && prebuild.presets.tower.map(ps => <>{presetData.filter(psgrp => psgrp.type === "tower")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Motherboard</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.mob.length > 0 && prebuild.presets.mob.map(ps => <>{presetData.filter(psgrp => psgrp.type === "mob")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>SSD</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.ssd.length > 0 && prebuild.presets.ssd.map(ps => <>{presetData.filter(psgrp => psgrp.type === "ssd")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Fans</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.fans.length > 0 && prebuild.presets.fans.map(ps => <>{presetData.filter(psgrp => psgrp.type === "fans")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Graphics Card</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.gpu.length > 0 && prebuild.presets.gpu.map(ps => <>{presetData.filter(psgrp => psgrp.type === "gpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>HDD</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.hdd.length > 0 && prebuild.presets.hdd.map(ps => <>{presetData.filter(psgrp => psgrp.type === "hdd")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>WiFi</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.wifi.length > 0 && prebuild.presets.wifi.map(ps => <>{presetData.filter(psgrp => psgrp.type === "wifi")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>RAM</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.ram.length > 0 && prebuild.presets.ram.map(ps => <>{presetData.filter(psgrp => psgrp.type === "ram")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Cooler</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.cooler.length > 0 && prebuild.presets.cooler.map(ps => <>{presetData.filter(psgrp => psgrp.type === "cooler")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Operating System</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.os.length > 0 && prebuild.presets.os.map(ps => <>{presetData.filter(psgrp => psgrp.type === "os")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Build Fees</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.build_fees.length > 0 && prebuild.presets.build_fees.map(ps => <>{presetData.filter(psgrp => psgrp.type === "build_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Shipping Fees</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.shp_fees.length > 0 && prebuild.presets.shp_fees.map(ps => <>{presetData.filter(psgrp => psgrp.type === "shp_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                            <td>Warranty</td>
                                                            <td colSpan="2">
                                                                {prebuild.presets.warranty.length > 0 && prebuild.presets.warranty.map(ps => <>{presetData.filter(psgrp => psgrp.type === "warranty")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>)}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                )}
                                            </td>
                                        </tbody>
                                        }
                                    </table>
                                </td>
                                <td>
                                {editid === prebuild.productid ? (
                                    <>
                                    <button onClick={submitHandler}>Save</button>
                                    <button style={{ marginTop: "10px" }} onClick={cancelHandler}>Cancel</button>
                                    </>
                                ) : (
                                    <button onClick={() => {buildeditHandler(prebuild.productid)}}>Edit</button>
                                )}
                                </td>
                            </tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        products: state.products
    }
}
export default connect(mapStateToProps)(PreBuilts)